<template>
  <li
    :class="[
      'sc-menu-item',
      isHover ? 'sc-menu-item-hover' : '',
      String(selectedItem[replaceFields.key]) ===
        String(menuData[replaceFields.key])
        ? 'sc-menu-item-active'
        : ''
    ]"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @click="handleClick"
  >
    <!-- :style="{ paddingLeft: Number(indentCalculate) * inlevel + indentUnit }" -->
    <slot />
  </li>
</template>

<script>
export default {
  name: 'ScMenuItem',
  props: {
    menuData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    indent: {
      // 菜单缩进宽度
      type: [Number, String],
      default: 0
    },
    selectedKey: {
      // 初始选中的菜单项key
      type: [Number, String],
      default: ''
    },
    selectedItem: {
      // 初始选中的菜单项
      type: Object,
      default: function() {
        return {}
      }
    },
    replaceFields: {
      // 替换dataList中key,children字段为replaceFields的属性，暂时未开发
      type: Object,
      default: function() {
        return { children: 'children', key: 'id' }
      }
    },
    inlevel: {
      // 内置层级，不需要传参
      type: Number,
      default: 1
    },
    inIndex: {
      // 内置序号，不需要传参
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      isHover: false, // 鼠标是否悬浮
      indentCalculate: '', // 缩进宽度处理后的值
      indentUnit: 'px' // 缩进宽度处理后的单位
    }
  },
  watch: {
    'selectedKey': {
      handler: function(val) {
        // 如果选择值和项Id一致，则自动选中该项
        if (val === this.menuData[this.replaceFields.key]) {
          this.handleClick()
        }
      },
      immediate: true
    },
    'indent': {
      handler: function(val) {
        if (/^[0-9]+$/.test(val)) {
          this.indentCalculate = String(val)
          this.indentUnit = 'px'
        } else {
          this.indentCalculate = String(val).replace(/[^0-9.]/g, '')
          this.indentUnit = String(val).replace(/^[0-9.]+/g, '')
        }
      },
      immediate: true
    }
  },
  methods: {
    handleMouseEnter() {
      // 鼠标移入
      this.isHover = true
    },
    handleMouseLeave() {
      // 鼠标移出
      this.isHover = false
    },
    handleClick() {
      this.$emit(
        'update:selectedKey',
        String(this.menuData[this.replaceFields.key])
      )
      this.$emit('update:selectedItem', this.menuData)
    }
  }
}
</script>

<style style="less" scoped>
.sc-menu-item {
  width: 100%;
  margin-bottom: 8px;
  padding-left: 16px;
  cursor: pointer;
}
</style>
