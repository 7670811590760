<!--
 * @Date: 2022-06-30 08:58:55
 * @descriotion: 考勤选择使用人员
-->
<template>
  <div>
    <a-button prefix="add-one" @click="modal = true">
      选择部门和员工
    </a-button>
    <div v-if="perDataList" class="scopeList">
      <div v-for="i in (perDataList.length > 6 ? 6 : perDataList.length)" :key="i" class="person">
        <CbAvater
          :on-job-id="perDataList[i-1].onJobId"
          :avater-name="perDataList[i-1].staffName"
          :default-name-length="1"
          size="22"
        />
        <svg class="iconpark-icon close" @click="delUser(perDataList[i-1].onJobId)"><use href="#close-small" /></svg>
      </div>
      <a-popover title="适用人员" placement="left" trigger="hover" overlay-class-name="thePopover">
        <template slot="content">
          <div class="scopeList popoverContent">
            <div v-for="item in perDataList" :key="item.onJobId" class="person">
              <CbAvater
                :on-job-id="item.onJobId"
                :avater-name="item.staffName"
                :default-name-length="1"
                size="22"
                width="55"
              />
              <svg class="iconpark-icon close" @click="delUser(item.onJobId)"><use href="#close-small" /></svg>
            </div>
          </div>
        </template>
        <div v-show="perDataList.length > 6" class="num">+{{ perDataList.length - 6 }}</div>
      </a-popover>
    </div>
    <PerModelSelect
      v-model="modal"
      :query-leave-person="queryLeavePerson"
      :placeholder="'请输入部门、人员、工号'"
      :data-echo="dataEcho"
      @perModelConfirm="perModelConfirm"
    />
  </div>
</template>
<script>
import mixinsPer from '@/components/CbPerDepSelection/index.js'
export default {
  name: 'CbSelectPersons',
  components: {
    CbAvater: () => import('@/components/CbAvater/theAvater')
  },
  mixins: [mixinsPer],
  props: {
    dataEcho: {
      type: Array,
      default: () => []
    },
    queryLeavePerson: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false,
      perDataList: []
    }
  },
  watch: {
    dataEcho: {
      immediate: true,
      handler(val) {
        this.perDataList = val
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    perModelConfirm(data) {
      this.modal = false
      this.$emit('perModelConfirm', data)
    },
    delUser(onJobId) {
      this.perDataList = this.perDataList.filter((v) => v.onJobId !== onJobId)
      this.$emit('perModelConfirm', this.perDataList)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-btn .anticon{
  color: @sc-primary-100;
}
.scopeList{
    width:100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  .person{
    width:100px;
    margin: 10px 10px 0 0;
    height:26px;
    border-radius: 13px;
    padding: 5px 10px 5px 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:  @sc-greyBg-10;
    /deep/ .box .user-name{
      width: calc(100% - 35px) !important;
    }
  }
  .num{
    cursor: pointer;
    margin: 10px 10px 0 0;
    color: @sc-primary-100;
    font-weight: 600;
    &:hover{
      color: @sc-primary-60;
    }
  }
}
.popoverContent{
    width:550px;
    .person{
      width:100px;
    }
  }
.close{
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: @sc-greyBg-100;
}
</style>
<style>
.thePopover .ant-popover-inner-content{
  max-height: 400px;
  overflow: auto;
}
</style>
