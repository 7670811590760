<!--
 * @Date: 2022-08-09 15:40:40
 * @descriotion:
-->
<template>
  <div class="percentage">
    <CBNumber
      v-model="showVal"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
export default {
  name: 'Thousand',
  components: {
    CBNumber: () => import('./index')
  },
  inheritAttrs: true,
  model: {
    prop: 'defultVal',
    event: 'echoVal',
    intVal: 'intVal'
  },
  props: {
    defultVal: {
      type: [String, Number],
      default: null
    },
    intVal: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      // 展示的数字
      showVal: '',
      // 实际的数字
      realVal: ''
    }
  },
  watch: {
    defultVal: {
      immediate: true,
      handler(val) {
        console.log(this.intVal, '==val0')
        // if (this.defultVal === '0' || this.defultVal === 0 || this.defultVal === '') {
        //   this.showVal = ''
        //   console.log(val, '==val1')
        // } else {
        //   console.log(val, '==val2')
        //   const vals = this.stateFormatNum(val)
        //   this.showVal = vals
        //   console.log(vals, '==展示')
        // }
        if (this.intVal === '0' || this.intVal === 0 || this.intVal === '') {
          this.showVal = ''
        } else {
          this.showVal = this.stateFormatNum(this.intVal)
        }
      }
    },
    showVal(val) {
      const vals = this.strToNumber(val)
      this.realVal = Number(vals)
      console.log(vals, '==返回')
      this.$emit('blur', vals)
    }
  },
  mounted() {
  },
  methods: {
    strToNumber(obj) {
      var reg = /^[0-9]+.?[0-9]*$/
      if (!(reg.test(obj))) {
        if ((obj + '').replace(/^\s\s*/, '').replace(/\s\s*$/, '') === '') {
          return 0.00
        }
        if (obj === undefined) {
          return 0.00
        }
        if ((obj || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')) {
          obj = obj.replace(/,/gi, '')
          return obj
        }
      }
      return obj
    },
    stateFormatNum(value) {
      if (!value || !Number(value)) return 0
      const intPart = Math.trunc(value)
      let intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      if (value < 0) {
        intPartFormat = '-' + 0
      }
      let floatPart = ''
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    }
  }
}
</script>

<style lang="less" scoped>

</style>
