<template>
  <div>
    <div class="color-display">
      <div id="select" class="color-select" :style="`background-color:rgba(${colorVal})`" @click="openPanel" />
    </div>
    <div v-show="isSelect" id="box" class="color-panel">
      <div v-for="(item,i) in colorList" :key="i" class="color" :style="`background:rgba(${item.fontRgba})`" @click="selectColor(item)"><div class="mask" /></div>
    </div>

  </div>
</template>

<script>
import { getcolorTheme } from '@/services/attendance/color.js'
export default {
  name: 'CbColorPicker',
  model: {
    props: 'value',
    event: 'colorChange'
  },
  props: {
    value: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      colorVal: '',
      isSelect: false,
      colorList: []
    }
  },
  watch: {
    color(val) {
      if (val) {
        this.colorVal = val
      }
    }
  },
  mounted() {
    this.getcolorThemeList()
    // 监听是否点击事件 如不在颜色选择面板上则面板关闭
    document.addEventListener('click', e => {
      const box = document?.getElementById('box')
      const select = document?.getElementById('select')
      if (!select?.contains(e.target) && !box?.contains(e.target)) {
        this.isSelect = false
      }
    })
  },
  methods: {
    // 查询系统颜色
    async getcolorThemeList() {
      const res = await getcolorTheme()
      if (res.code === 100) {
        res.data.forEach(color => {
          // 回显颜色
          if (color.colorThemeCode === this.value) {
            this.colorVal = color.fontRgba
            return
          }
        })
        this.colorList = res.data
      }
    },
    openPanel() {
      this.isSelect = true
    },
    selectColor({ fontRgba, colorThemeCode }) {
      this.colorVal = fontRgba
      this.isSelect = false
      // colorThemeCode 颜色code 根据回显查询过滤
      this.$emit('colorChange', colorThemeCode, fontRgba)
    }
  }
}
</script>
<style lang="less" scoped>
.color-display{
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    padding: 4px;
    border: 1px solid @border-color;
    cursor: pointer;
    .color-select{
       width: 100%;
       height: 100%;
    }
}
.color-panel{
    position: absolute;
    top: 34px;
    width: 226px;
    height: 142px;
    padding:16px 0 16px 16px;
    z-index: 99;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    box-shadow: 0px 4px 18px 1px @shadow-color;
    border-radius: 4px;
    .color{
      width:26px;
      height:26px;
      margin:0 16px 16px 0;
      cursor: pointer;
      position: relative;
    }
    .mask{
      display:none;
      position:absolute;
      left:26px;
      width:26px;
      height:26px;
      margin:0 16px 16px 0;
      cursor: pointer;
      background:rgba(0,0,0,0.2)
    }
    .color:hover .mask{
      display:block;
      left:0px;
    }
}
</style>
