<template>
  <li class="sc-menu-submenu">
    <div v-if="menuData.disabled" class="sc-menu-submenu-title" :style="{ paddingLeft: Number(indentCalculate) * inlevel + indentUnit }">
      <slot
        name="submenuTitle"
        :data="{
          info: menuData,
          path: `${inPath}`
        }"
      />
    </div>
    <div
      v-else
      :class="[
        'sc-menu-submenu-title',
        isHover? 'sc-menu-submenu-hover' : '',
        String(selectedItem[replaceFields.key]) ===
          String(menuData[replaceFields.key])
          ? 'sc-menu-submenu-active'
          : ''
      ]"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @click="handleClick"
    >
      <slot
        name="submenuTitle"
        :data="{
          info: menuData,
          path: `${inPath}`
        }"
      />
    </div>
    <scMenu
      class="sc-menu-inner"
      :data-list="menuData[replaceFields.children]"
      :indent="indent"
      :inlevel="inlevel + 1"
      :in-path="inPath"
      :selected-key.sync="selectedKeyInner"
      @change="menuSelectChange"
    >
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </scMenu>
  </li>
</template>

<script>
export default {
  name: 'ScSubmenu',
  components: {
    scMenu: () => import('./menu.vue')
  },
  props: {
    menuData: {
      type: Object,
      default: function() {
        return {}
      }
    },
    indent: {
      // 菜单缩进宽度
      type: [Number, String],
      default: 0
    },
    selectedKey: {
      // 初始选中的菜单项key
      type: [Number, String],
      default: ''
    },
    selectedItem: {
      // 初始选中的菜单项
      type: Object,
      default: function() {
        return {}
      }
    },
    replaceFields: {
      // 替换dataList中key,children字段为replaceFields的属性，暂时未开发
      type: Object,
      default: function() {
        return { children: 'children', key: 'id' }
      }
    },
    inlevel: {
      // 内置层级，不需要传参
      type: Number,
      default: 1
    },
    inIndex: {
      // 内置序号，不需要传参
      type: Number,
      default: 1
    },
    inPath: {
      // 内置序号，不需要传参
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedKeyInner: this.selectedKey,
      isHover: false, // 鼠标是否悬浮
      indentCalculate: '', // 缩进宽度处理后的值
      indentUnit: 'px' // 缩进宽度处理后的单位
    }
  },
  watch: {
    'selectedKey': {
      handler: function(val) {
        this.selectedKeyInner = val
        if (val === this.menuData[this.replaceFields.key]) {
          this.handleClick()
        }
      },
      immediate: true
    },
    'indent': {
      handler: function(val) {
        if (/^[0-9]+$/.test(val)) {
          this.indentCalculate = String(val)
          this.indentUnit = 'px'
        } else {
          this.indentCalculate = String(val).replace(/[^0-9.]/g, '')
          this.indentUnit = String(val).replace(/^[0-9.]+/g, '')
        }
      },
      immediate: true
    }
  },
  methods: {
    handleMouseEnter() {
      // 鼠标移入
      this.isHover = true
    },
    handleMouseLeave() {
      // 鼠标移出
      this.isHover = false
    },
    handleClick() {
      this.$emit(
        'update:selectedKey',
        String(this.menuData[this.replaceFields.key])
      )
      this.$emit('update:selectedItem', this.menuData)
    },
    // 子菜单选择回调
    menuSelectChange(data) {
      this.selectedKeyInner = String(data[this.replaceFields.key])
      this.$emit('update:selectedKey', String(data[this.replaceFields.key]))
      this.$emit('update:selectedItem', data)
    }
  }
}
</script>

<style lang="less" scoped>
.sc-menu-submenu {
  .sc-menu-submenu-title {
    margin-top: 4px;
    margin-bottom: 8px;
    color: @sc-grey-40;
    padding-left: 16px !important;
  }
  .sc-menu-inner {
    padding-left: 0;
  }
}
</style>
