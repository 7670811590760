/*
 * @Date: 2022-07-04 19:58:41
 * @descriotion:社保-账单
 */
import { INSURANCE } from '../api'
import { METHOD, request } from '@/utils/request'

// 获取当月参保主体账单列表
export async function getCompanyBillListApi(params) {
  return request(`${INSURANCE}/companyBill/getList`, METHOD.GET, params)
}

// 获取参保主体账单详情
export async function getCompanyBillDetailApi(params) {
  return request(`${INSURANCE}/companyBill/getCompanyBillDetail`, METHOD.GET, params)
}

// 获取某参保主体账单下当月的员工账单
export async function getInsureBillListApi(params) {
  return request(`${INSURANCE}/insureBill/getList`, METHOD.GET, params)
}

// 统计租户当月各项数值
export async function getMonthTenantBillApi(params) {
  return request(`${INSURANCE}/tenantBill/getMonthTenantBill`, METHOD.GET, params)
}

// 锁定账单
export async function updateStatusApi(params) {
  return request(`${INSURANCE}/tenantBill/updateStatus`, METHOD.POST, params)
}

// 获取租户账单明细
export async function getTenantBillDetailApi(params) {
  return request(`${INSURANCE}/tenantBill/getTenantBillDetail`, METHOD.GET, params)
}

// 获取租户下员工账单详情
export async function getInsureBillDetailApi(params) {
  return request(`${INSURANCE}/insureBill/getInsureBillDetail`, METHOD.GET, params)
}

// 更新员工账单补缴调差
export async function updateSupplyApi(params) {
  return request(`${INSURANCE}/insureBill/updateSupply`, METHOD.POST, params)
}

// 删除补缴差额
export async function deleteSupplyApi(params) {
  return request(`${INSURANCE}/insureBill/deleteSupply`, METHOD.POST, params)
}

// 创建账单
export async function createBillApi(params) {
  return request(`${INSURANCE}/tenantBill/createBill`, METHOD.POST, params)
}

// 下载导入模板
export async function exportBatchSupplyTemplateApi(params) {
  return request(`${INSURANCE}/companyBill/exportBatchSupplyTemplate`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 导入补缴调差
export async function batchImportSupplyApi(params) {
  return request(`${INSURANCE}/companyBill/batchImportSupply`, METHOD.POST, params)
}

// 导出社保增员、社保减员页面的社保/医保/公积金
export async function exportSocialZyApi(params) {
  return request(`${INSURANCE}/companyBill/exportSocialZy`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 获取增员列表
export async function getZyList(params) {
  return request(`${INSURANCE}/companyBill/getZyList`, METHOD.GET, params)
}

// 获取减员列表
export async function getJyList(params) {
  return request(`${INSURANCE}/companyBill/getJyList`, METHOD.GET, params)
}

// 导出员工账单页面的社保/医保/公积金
export async function exportInsureBillApi(params) {
  return request(`${INSURANCE}/companyBill/exportInsureBill`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 查询租户当月账单状态
export async function getCurrentMonthBillStatusApi(params) {
  return request(`${INSURANCE}/tenantBill/getCurrentMonthBillStatus`, METHOD.GET, params)
}

// 获取参保主体投保类型详情
export async function getInsureClassifyDetailApi(params) {
  return request(`${INSURANCE}/companyBill/getInsureClassifyDetail`, METHOD.GET, params)
}

// 删除账单
export async function deleteBillApi(params) {
  return request(`${INSURANCE}/tenantBill/deleteBill`, METHOD.POST, params)
}

// 获取补缴列表
export async function getSupplyListApi(params) {
  return request(`${INSURANCE}/companyBill/getSupplyList`, METHOD.GET, params)
}

// 获取员工补缴详情
export async function supplyPayDetailApi(params) {
  return request(`${INSURANCE}/supplyPay/supplyPayDetail`, METHOD.GET, params)
}

// 官方系统快捷登录
export async function getSystemUrlApi(params) {
  return request(`${INSURANCE}/companyBill/getSystemUrl`, METHOD.GET, params)
}

// 一键导出申报文件
export async function exportNotificationDocumentApi(params) {
  return request(`${INSURANCE}/companyBill/exportNotificationDocument`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 导出汇总
export async function exportInsureBillCollectApi(params) {
  return request(`${INSURANCE}/companyBill/exportInsureBillCollect`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}
// 补缴导出
export async function exportSupplyPayApi(params) {
  return request(`${INSURANCE}/supplyPay/exportSupplyPay`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 导出账单模板
export async function exportBatchBillTemplateApi(params) {
  return request(`${INSURANCE}/tenantBill/exportBatchBillTemplate`, METHOD.GET, params, { responseType: 'blob', headers: { isDownload: true }})
}

// 导入账单
export async function batchImportBillApi(params) {
  return request(`${INSURANCE}/tenantBill/batchImportBill`, METHOD.POST, params)
}
