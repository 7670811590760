<!--
 * @Date: 2022-07-12 17:37:32
 * @descriotion:
-->
<template>
  <Modal v-model="show" :title="title" :z-index="8" :closable="true" overflow-auto :mask-closable="false" @ok="handleOk" @cancel="cancel">
    <div class="content">
      <div v-for="(item,index) in dataList" :key="item.onJobId" class="list">
        <div>
          <div class="user">
            <!-- <CbTableAvater :table-name="item.staffName" :staff-id="item.staffId" /> -->
            <CbAvater :avater-name="item.staffName" :on-job-id="item.onJobId" :staff-id="item.staffId" />
            <div />
          </div>
        </div>
        <a-select v-model="item[replaceGroupKey]" :placeholder="placeholder" style="width:230px" :disabled="item[replaceIgnoreTypeKey]">
          <a-select-option v-for="(select,i) in selectList" :key="i" :value="select[replaceFields.value]">
            {{ select[replaceFields.label] }}
          </a-select-option>
        </a-select>
        <div class="btn" @click="editStaffIgnoreType(item,index)">{{ item[replaceIgnoreTypeKey]?'撤销忽略':'忽略' }}</div>
      </div>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'CbIgnore',
  components: {
    // CbTableAvater: () => import('@/components/CbTableAvater/index')
    CbAvater: () => import('@/components/CbAvater/theAvater.vue'),
    Modal: () => import('../CbModal/index.vue')
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    // 人员列表
    dataPrompt: {
      type: Array,
      default: () => []
    },
    // 替换所属分组-key
    replaceGroupKey: {
      type: String,
      default: ''
    },
    // 是否忽略字段替换的key
    replaceIgnoreTypeKey: {
      type: String,
      default: 'ignoreType'
    },
    // 下拉选择列表
    selectList: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: true
    },
    // 替换a-select-option  value label {label:'label',value:'value'}
    replaceFields: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      show: this.visible,
      dataList: []
    }
  },
  watch: {
    visible(val) {
      this.show = val
    },
    dataPrompt: {
      immediate: true,
      handler: function(val) {
        this.dataList = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    editStaffIgnoreType(item, index) {
      this.dataList[index][this.replaceGroupKey] = undefined
      this.dataList[index][this.replaceIgnoreTypeKey] = !this.dataList[index][this.replaceIgnoreTypeKey]
      // this.$emit('isIgnore', item)
    },
    handleOk() {
      this.$emit('setPerson', this.dataList)
    },
    cancel() {
      this.show = false
      this.$emit('cancel', false)
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  .list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:50px;
    .user{
      width:150px;
      overflow: hidden;
      display: flex;
      align-items: center;
      .userId{
        margin:0 24px 0 10px;
      }
    }
  }
  .btn{
    width:100px;
    text-align: right;
    cursor: pointer;
    color: @sc-primary-100
  }
}
</style>
