<template>
  <div class="HistoryPopover">
    <a-popover
      v-model="historyVisible"
      title="近期访问"
      trigger="click"
      placement="bottomRight"
      :get-popup-container="triggerNode => { return triggerNode.parentNode}"
      overlay-class-name="history-popover"
    >
      <template slot="content">
        <div class="popover-content">
          <div class="popover-history">
            <div class="history-content">
              <template v-if="historyList && historyList.length > 0">
                <div v-for="(history,i) in historyList" :key="i" class="history flex-align-center" @click="jumpRoute(history.routePath, history)">
                  <span class="first-route">{{ history.firstRouteName }}</span>
                  <span class="second-route">{{ history.secondRouteName }}</span>
                </div>
              </template>
              <template v-else>
                <NoData />
              </template>
            </div>
          </div>
        </div>
      </template>
      <slot name="icon" />
    </a-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HistoryPopover',
  components: {
    NoData: () => import('@/components/CbNoData')
  },
  props: {},
  data() {
    return {
      historyVisible: false,
      historyList: []
    }
  },
  computed: {
    ...mapGetters('setting', ['getRecentVisits'])
  },
  watch: {
    getRecentVisits(recentVisits) {
      const tenantInfo = JSON.parse(localStorage.getItem(process.env.VUE_APP_TENANT_KEY))
      const tenantId = tenantInfo && tenantInfo.tenantId
      this.historyList = recentVisits && recentVisits[tenantId]
    }
  },
  mounted() {
    const tenantInfo = JSON.parse(localStorage.getItem(process.env.VUE_APP_TENANT_KEY))
    const tenantId = tenantInfo && tenantInfo.tenantId
    this.historyList = this.getRecentVisits && this.getRecentVisits[tenantId]
  },
  methods: {
    jumpRoute(routerPath, history) {
      // if (history.secondRouteName === '设置') {

      // }
      // console.log(history)
      this.$router.push({
        path: routerPath
      })
      this.historyVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.history-popover {
  .popover-content {
    width: 204px;
    .popover-history {
      max-height: 250px;
      width: 204px;
      .history-content {
        padding: 0 5px;
        .history {
          cursor: pointer;
          width: 194px;
          height: 34px;
          padding-left: 5px;
          margin-top: 5px;
          .font-size(14px);
          .icon {
            width: 13.5px;
            height: 13.5px;
            border: 1px solid #000;
          }
          .first-route {
            color: @sc-grey-100;
            margin: 0 12px 0 7px;
          }
          .second-route {
            color: @sc-grey-60;
          }
        }
        .history:hover {
          background: @sc-greyBg-10;
          border-radius: 4px;
        }
      }
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-content {
    margin-top: 14px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  // 滚动条
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: @sc-greyBg-10;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
}
/deep/ .ant-popover-title {
    color: @sc-grey-60;
    .font-size(14px);
    padding-left: 12px !important;
  }
/deep/ .ant-popover-inner {
      border-radius: 6px;
  }
</style>
