var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:[
    'sc-menu-item',
    _vm.isHover ? 'sc-menu-item-hover' : '',
    String(_vm.selectedItem[_vm.replaceFields.key]) ===
      String(_vm.menuData[_vm.replaceFields.key])
      ? 'sc-menu-item-active'
      : ''
  ],on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"click":_vm.handleClick}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }